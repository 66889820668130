<template>
  <div id="app">
    <div class="fixed top-0 left-0 w-screen h-screen bg-white flex justify-center items-center z-50 transition-opacity duration-1000" :class="opening? 'opacity-100': ' opacity-0 pointer-events-none'">
      <img class=" w-48 md:w-64" src="/images/logo-opening.png">
    </div>
    <GlobalNav />
    <div v-if="!isMobile" class="fixed-white-frame fixed z-10 top-0 left-0 w-screen h-screen pointer-events-none">
      <img src="/images/logo-header.png" class=" mt-8 w-40 block mx-auto">
      <img src="/images/bg/1.svg" class=" absolute -top-5 -left-20 opacity-30">
      <img src="/images/bg/2.svg" class=" absolute -top-16 right-5 opacity-30">
      <img src="/images/bg/4.svg" class=" absolute -bottom-16 -left-16 opacity-30">
      <img src="/images/bg/3.svg" class=" absolute -bottom-5 -right-14 opacity-30">
    </div>
    <div v-else class="fixed z-10 left-0 w-screen pointer-events-none h-16 bg-white bg-opacity-80 flex items-center pl-3">
        <img src="/images/logo-mobile1.png" class=" w-28 h-auto">
        <img src="/images/logo-mobile2.png" class=" ml w-40 ml-4 h-auto">
    </div>

    <First />
    <div class="md:hidden relative z-10 mix-blend-multiply h-5">
      <div class=" absolute -top-5 left-0 w-screen h-40 pointer-events-none overflow-hidden">
        <img src="/images/bg/1.gif" class="absolute top-0 -left-4 opacity-30" style="width: 300px;">
      </div>
    </div>
    <section v-if="!isMobile" class="py-5" id="concept">
      <div class="container mx-auto pt-10">
        <ScrollDetector @enter-client-rect="sec1_1Enter=true">
        <div class="transition-all duration-1000 transform" :class="sec1_1Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
          <h6 class="din text-3xl"><span class=" inline-block border-b-2 pb-2 border-black">1</span></h6>
          <h3 class="din text-6xl tracking-wide mt-6">CONCEPT</h3>
        </div>
        </ScrollDetector>
        <ScrollDetector @enter-client-rect="sec1_2Enter=true">
          <h4 class="text-sm font-bold mt-3 transition-all duration-1000 transform" :class="sec1_2Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">くろくろが大切にする3つのこと</h4>
        </ScrollDetector>
      </div><!-- /.container -->
      <div class="flex items-center mt-10">
        <div class="w-5/12 lg:w-6/12">
          <div class=" -mr-7">
            <ScrollDetector @enter-client-rect="sec1_3Enter=true">
              <img src="/images/concept/1.jpg" class="w-100 h-auto transition-all duration-1000 transform" :class="sec1_3Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
            </ScrollDetector>
          </div>
        </div>
        <div class="ml-20 w-72 lg:w-96">
          <ScrollDetector @enter-client-rect="sec1_4Enter=true">
            <div class="transition-all duration-1000 transform" :class="sec1_4Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
              <h5 class="tsukushi text-3xl lg:whitespace-nowrap">次の50年も変わらない味を。</h5>
              <p class="tsukushi regular text-sm leading-7 mt-4 text-justify">
                創業したのは、1972年。創業当時から変わらないこと。それは、『ずっと食べたい料理、長く食べたいものは、なんだろうと考え続ける』こと。『自分たちがおいしいと自信を持って言える料理をお客さんに食べてもらう』こと。そんな想いもあってか、ありがたいことに創業から50年変わらないオムライス、ハンバーグ、グラタン、クリームコロッケたちは今も愛され続けています。でも、くろくろの味の一番のファンは私たちかもしれません。これからもくろくろの味をきちんと届けていきます。
              </p>
            </div>
          </ScrollDetector>
        </div>
      </div><!-- /.flex -->
      <div class="flex justify-end items-center mt-20">
        <div class="pl-10 lg-pl-0 mr-20 w-72 lg:w-96">
          <ScrollDetector @enter-client-rect="sec1_5Enter=true">
            <div class="transition-all duration-1000 transform" :class="sec1_5Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
              <h5 class="tsukushi text-3xl lg:whitespace-nowrap">くろくろの当たり前。</h5>
              <p class="tsukushi regular text-sm leading-7 mt-4 text-justify">
                食材は近くの市場で、目利きする。お米とお野菜はもちろん無農薬。50年変わらない味、料理の肝になるホワイトソース、デミグラスソースをはじめ、野菜を惹き立てるドレッシングだって、全ていちから手作りです。「なんでわざわざ？」と聞かれることもありますが、くろくろにとったら当たり前。時間をかけて手間暇かけて作るからこそ、出会える味を、喜んでもらえる味を、知っているからです。
              </p>
            </div>
          </ScrollDetector>
        </div>
        <div class="w-1/2">
          <div class=" -ml-7">
            <ScrollDetector @enter-client-rect="sec1_6Enter=true">
              <img src="/images/concept/2.jpg" class="w-100 h-auto transition-all duration-1000 transform" :class="sec1_6Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
            </ScrollDetector>
          </div>
        </div>
      </div><!-- /.flex -->
      <div class="flex items-center mt-20">
        <div class="w-5/12 lg:w-6/12">
          <div class=" -mr-7">
            <ScrollDetector @enter-client-rect="sec1_7Enter=true">
              <img src="/images/concept/3.jpg" class="w-100 h-auto transition-all duration-1000 transform" :class="sec1_7Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
            </ScrollDetector>
          </div>
        </div>
        <div class="ml-20 w-72 lg:w-96">
          <ScrollDetector @enter-client-rect="sec1_8Enter=true">
            <div class="transition-all duration-1000 transform" :class="sec1_8Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
              <h5 class="tsukushi text-3xl lg:whitespace-nowrap">お客さんからの嬉しい言葉。</h5>
              <p class="tsukushi regular text-sm leading-7 mt-4 text-justify">
                地域から遠方から、お客さんが毎日訪れてくれます。初めてくろくろの料理を食べたお客さんからの「おいしかった」の一言。販売中の特製のスープを２年間、毎週買ってくださるお客さんからの「こんな食べ方したよ」という声。約25年間、毎日来てくれるおっちゃんの「ぼけたらあかんから、くろくろ行きやって家族に言われるねん」という話。どれも嬉しい言葉です。常連さんも、初めましてのお客さんにも変わらず、私たちは丁寧な料理、丁寧な接客でお待ちしています。くろくろでお会いできるのを楽しみにしています。
              </p>
            </div>
          </ScrollDetector>
        </div>
      </div><!-- /.flex -->
    </section>

    <ConceptMobile id="concept" v-else />
    <div class="md:hidden relative z-10 mix-blend-multiply">
      <div class=" absolute -top-24 left-0 w-screen h-80 pointer-events-none overflow-hidden">
        <img src="/images/bg/4.gif" class="absolute top-0 -right-16 opacity-20">
      </div>
    </div>

    <MenuCarousel v-if="!isMobile" />
    <MenuMobile v-else />

    <div class="md:hidden relative z-10 mix-blend-multiply">
      <div class=" absolute top-10 left-0 w-screen h-80 pointer-events-none overflow-hidden">
        <img src="/images/bg/5.gif" class="absolute top-0 -left-10 opacity-20">
      </div>
    </div>

    <section class="py-5 mt-10" id="takeout">
      <div class="container mx-auto px-3 md:px-0">
        <ScrollDetector @enter-client-rect="sec3_1Enter=true">
          <div class="transition-all duration-1000 transform" :class="sec3_1Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
            <h6 class="din text-2xl md:text-3xl text-center md:text-left"><span class=" inline-block border-b-2 pb-2 border-black">3</span></h6>
          </div>
        </ScrollDetector>
        <ScrollDetector @enter-client-rect="sec3_2Enter=true">
          <div class="transition-all duration-1000 transform" :class="sec3_2Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
            <h3 class="din text-4xl md:text-6xl tracking-wide mt-6 text-center md:text-left">TAKE OUT</h3>
            <h4 class="text-sm font-bold mt-3 text-center md:text-left">くろくろのこだわりのテイクアウトメニュー</h4>
          </div>
        </ScrollDetector>
      </div>
      <ScrollDetector @enter-client-rect="sec3_3Enter=true">
        <div class="container mx-auto transition-all duration-1000 transform" :class="sec3_3Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
          <img src="/images/takeout@2x.jpg" class="w-full h-auto mt-10">
        </div>
      </ScrollDetector>
      <ScrollDetector @enter-client-rect="sec3_4Enter=true">
        <div class="container mx-auto px-6 md:px-0 transition-all duration-1000 transform" :class="sec3_4Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
          <a href="/images/documents/takeout_menu.jpg" target="_blank" class=" block bg-white border-2 border-black font-bold mt-7 text-center md:text-xl py-4 hover:bg-gray-100 transition-colors">
            全てのメニューを見る
          </a>
        </div>
      </ScrollDetector>
    </section>

    <div class="md:hidden relative z-10 mix-blend-multiply">
      <div class=" absolute top-6 left-0 w-screen h-80 pointer-events-none overflow-hidden">
        <img src="/images/bg/6.gif" class="absolute top-0 -right-10 opacity-20">
      </div>
    </div>

    <section class="py-5 mt-10" id="information">
      <div class="container mx-auto px-5 md:px-0">
        <ScrollDetector @enter-client-rect="sec4_1Enter=true">
          <div class="transition-all duration-1000 transform" :class="sec4_1Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
            <h6 class="din text-2xl md:text-3xl text-center md:text-left"><span class=" inline-block border-b-2 pb-2 border-black">4</span></h6>
          </div>
        </ScrollDetector>
        <ScrollDetector @enter-client-rect="sec4_2Enter=true">
          <div class="transition-all duration-1000 transform" :class="sec4_2Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
            <h3 class="din text-3xl md:text-6xl tracking-wide mt-6 text-center md:text-left">INFORMATION</h3>
            <h4 class="text-sm font-bold mt-3 mb-10 text-center md:text-left">くろくろの店舗情報</h4>
          </div>
        </ScrollDetector>
        <div class="md:grid grid-cols-7 gap-5">
          <div class=" col-span-4">
            <ScrollDetector @enter-client-rect="sec4_3Enter=true">
              <img class="w-full h-auto transition-all duration-1000 transform" :class="sec4_3Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'" src="/images/information@2x.jpg">
            </ScrollDetector>
          </div>
          <div class=" col-span-3 mt-4 md:mt-0">
            <ScrollDetector @enter-client-rect="sec4_4Enter=true">
              <div class="transition-all duration-1000 transform" :class="sec4_4Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
                <h5 class=" font-semibold text-xl">洋食専門店くろくろ</h5>
                <h6 class=" font-semibold text-xs mt-4">【住所】</h6>
                <address class="font-semibold not-italic mt-2">〒573-1196&emsp;大阪府枚方市中宮本町3-11</address>
                <h6 class=" font-semibold text-xs mt-4">【営業時間】</h6>
                <p class="font-semibold mt-2">lunch&emsp;11:00 ~ 15:00 (L.o 14:30)<br>dinner&emsp;17:00 ~ 20:00 (L.o 19:30)</p>
                <h6 class=" font-semibold text-xs mt-4">【電話番号】</h6>
                <p class="font-semibold mt-2">072-849-8026</p>
                <h6 class=" font-semibold text-xs mt-4">【定休日】</h6>
                <p class="font-semibold mt-2">火曜日、水曜日</p>
              </div>
            </ScrollDetector>
          </div>
        </div>
      </div>
    </section>

    <div class="md:hidden relative z-10 mix-blend-multiply">
      <div class=" absolute -top-16 left-0 w-screen h-80 pointer-events-none overflow-hidden">
        <img src="/images/bg/2.gif" class="absolute top-0 -left-10 opacity-20">
      </div>
    </div>

    <section class="py-5 mt-10">
      <div class="container mx-auto">
        <ScrollDetector @enter-client-rect="sec5_1Enter=true">
          <div class="transition-all duration-1000 transform" :class="sec5_1Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
            <h6 class="din text-2xl md:text-3xl text-center md:text-left"><span class=" inline-block border-b-2 pb-2 border-black">5</span></h6>
          </div>
        </ScrollDetector>
        <ScrollDetector @enter-client-rect="sec5_2Enter=true">
          <div class="transition-all duration-1000 transform" :class="sec5_2Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
            <h3 class="din text-4xl md:text-6xl tracking-wide mt-6 text-center md:text-left">RESERVATION</h3>
            <h4 class="text-sm font-bold mt-3 text-center md:text-left">オンライン予約</h4>
          </div>
        </ScrollDetector>
        <div class="px-6 md:px-0">
          <ScrollDetector @enter-client-rect="sec5_4Enter=true">
            <div class="transition-all duration-1000 transform" :class="sec5_4Enter? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
              <a href="https://yoyaku.tabelog.com/yoyaku/net_booking_form/index?rcd=27011131" target="_blank" class=" block bg-white border-2 border-black font-bold mt-7 text-center md:text-xl py-4 hover:bg-gray-100 transition-colors">
                予約はこちら
              </a>
            </div>
          </ScrollDetector>
        </div>
      </div>
    </section>
    <footer class="pt-10 pb-32">
      <div class=" flex justify-center">
        <button v-scroll-to="'#app'" class="w-20 h-20 text-white bg-gray-400 rounded-full flex justify-center items-center hover:bg-gray-600 transition-colors">
          <div>
            <svg class=" block mx-auto" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" width="25" height="13" viewBox="0 0 20 10">
              <polyline fill="none" stroke="currentColor" stroke-width="2" points="1,9 10,1 19,9"></polyline>
            </svg>
            <p class="din mt-1 text-lg">TOP</p>
          </div>
        </button>
        <a href="https://www.instagram.com/cloclo.1972/" target="_blank" class="hidden ml-10 w-20 h-20 border-2 border-black bg-white rounded-full md:flex justify-center items-center hover:bg-gray-100 transition-colors">
          <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 448 512">
            <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
          </svg>
        </a>
      </div>
    </footer>
    <!-- <a class="md:hidden fixed w-24 h-24 rounded-full bg-white bg-opacity-80 bottom-4 left-4 p-3 flex justify-center items-center" href="https://thebase.in/to_app?s=shop&amp;shop_id=kurokuroshop-official-ec&amp;follow=true" target="_blank">
      <div>
        <img class="w-full h-auto" src="/images/icon-shop.png">
        <p class="tsukushi text-xs mt-2">SHOPを見る</p>
      </div>
    </a> -->
  </div><!-- /#app -->
</template>

<script>
import ScrollDetector from "vue-scroll-detector"
import utilMixin from './mixins/utils'
import First from './components/First.vue'
import ConceptMobile from './components/ConceptMobile.vue'
import MenuCarousel from './components/MenuCarousel.vue'
import MenuMobile from './components/MenuMobile.vue'
import GlobalNav from './components/GlobalNav.vue'

export default {
  name: 'App',
  components: {
    ScrollDetector, First, ConceptMobile, MenuCarousel, MenuMobile, GlobalNav
  },
  mixins: [
    utilMixin
  ],
  data() {
    return {
      opening: true,
      sec1_1Enter: false,
      sec1_2Enter: false,
      sec1_3Enter: false,
      sec1_4Enter: false,
      sec1_5Enter: false,
      sec1_6Enter: false,
      sec1_7Enter: false,
      sec1_8Enter: false,
      sec1_9Enter: false,
      sec1_10Enter: false,
      sec3_1Enter: false,
      sec3_2Enter: false,
      sec3_3Enter: false,
      sec3_4Enter: false,
      sec4_1Enter: false,
      sec4_2Enter: false,
      sec4_3Enter: false,
      sec4_4Enter: false,
      sec5_1Enter: false,
      sec5_2Enter: false,
      sec5_3Enter: false,
      sec5_4Enter: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.opening = false    
    }, 1000);
  }
}
</script>

<style>
.din {
  font-family: din-2014, sans-serif;
  font-weight: 600;
  font-style: normal;
}


.tsukushi {
  font-family: fot-tsukuardgothic-std,sans-serif;
  font-weight: 700;
  font-style: normal;
}
.tsukushi.regular{
  font-weight: 400;
}

body {
  background-color: #F5F5F5;
}

.fixed-white-frame:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border: 30px solid rgba(255, 255, 255, 0.6);
}
@media only screen and (min-width: 976px) {
  .fixed-white-frame:before {
    border: 80px solid rgba(255, 255, 255, 0.6);
  }
}

</style>